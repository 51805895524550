import React from "react";
import SectionHeader from "./SectionHeader";
import SkillTile from "./SkillTile";
import {useIntl} from "react-intl";


const SkillsContent = ({title, skills}) => {

    const intl = useIntl()

    return (
        <section id='skills'>
            <SectionHeader title={intl.formatMessage({id: "skills"})}/>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-16">
                {
                    skills.map(skill => {
                            const {frontmatter, content} = skill.childMarkdownRemark;
                            return (
                                <SkillTile key={frontmatter.name} content={content} {...frontmatter} />
                            )
                        }
                    )
                }
            </div>
        </section>
    )
}


export default SkillsContent
