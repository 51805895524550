import React from "react";
import SectionHeader from "./SectionHeader";
import ProjectTile from "./ProjectTile";
import {useIntl} from "react-intl";


const ProjectsContent = ({projects}) => {

    const intl = useIntl()

    return (
        <section id="projects">
            <SectionHeader title={intl.formatMessage({id: "projects"})}/>
            {
                projects.map((project, index) => {
                    const {frontmatter, content} = project.childMarkdownRemark
                    return (
                        <ProjectTile key={frontmatter.title} content={content}
                                     isEven={index % 2 === 0} {...frontmatter} />
                    );
                })
            }
        </section>
    )
}


export default ProjectsContent
