import React, {useState} from "react";
import SectionHeader from "./SectionHeader";
import CTAButton from "./CTAButton";
import {StaticImage} from "gatsby-plugin-image";
import {FormattedMessage, useIntl} from "react-intl";

const ContactContent = ({content, tagLine}) => {

    const intl = useIntl()
    const [hasCopied, setCopied] = useState(false)

    const copyEmailToClipboard = () => {
        console.log(navigator)
        console.log(navigator.clipboard)
        window.navigator.clipboard.writeText("galler.stefan@gmail.com")
        setCopied(true)
    }

    return (
        <section id="contact">
            <SectionHeader title={intl.formatMessage({id: "contact"})}/>
            <div className="flex flex-row items-center">
                <div className="text-center w-full md:w-1/2 flex flex-col items-stretch">
                    <span className="text-xl md:text-3xl font-bold">{tagLine}</span>
                    <div className="text-lg md:text-xl text-center  my-8 flex flex-col gap-2"
                         dangerouslySetInnerHTML={{__html: content}}/>
                    <div className="flex flex-col md:flex-row justify-center md:items-baseline mb-8 text-lg md:text-xl">
                        <div>
                            <strong><FormattedMessage id="email"/>:</strong>
                            <span> galler.stefan@gmail.com</span>
                        </div>
                        <button className="text-sm text-primary mx-1" onClick={() => copyEmailToClipboard()}>
                            {hasCopied ? intl.formatMessage({id: 'copied'}) : intl.formatMessage({id: 'copy'})}
                        </button>
                    </div>
                    <CTAButton text={intl.formatMessage({id: "contactCTA"})} onClick={() => {
                        window.location = "mailto:galler.stefan@gmail.com"
                    }}/>

                </div>
                <div className="hidden md:inline-block flex-1 ml-16">
                    <StaticImage
                        src="../content/images/placeholder.png" alt=""
                        quality={90}/>
                </div>
            </div>
        </section>
    )
}

export default ContactContent
