import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const SkillTile = ({name, image, content}) => {
    const img = getImage(image);
    return (
        <div className="flex flex-col items-center my-4">
            <GatsbyImage alt={name} image={img} />
            <span className="text-2xl md:text-3xl font-bold mt-4 mb-3 text-center">{name}</span>
            <div className="text-center text-lg" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    )
}

export default SkillTile
