import React from "react";

const CTAButton = ({text, onClick}) => {
    return (
        <button
            className="bg-secondary hover:bg-opacity-70 active:bg-opacity-90 rounded-tl-xl rounded-tr-3xl rounded-br-2xl rounded-bl-3xl p-4
            text-primary text-lg font-bold uppercase"
            onClick={onClick}>
            {text}
        </button>
    )
}

export default CTAButton
