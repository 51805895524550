import React from "react";

const SectionHeader = ({title}) => {
return (
    <div className="flex justify-center items-center h-32">
        <div className="bg-black h-1 w-full absolute" />
        <h2 className="text-3xl font-bold p-8 m-0 absolute bg-white">{title}</h2>
    </div>
)
}


export default SectionHeader
