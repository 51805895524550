import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CTAButton from "./CTAButton";
import {useIntl} from "react-intl";

const ProjectTile = ({title, subtitle, image, content, appStoreLink, playStoreLink, link, isEven}) => {

    const img = getImage(image)
    const intl = useIntl()

    return (
        <div
            className={`flex flex-col ${isEven ? 'lg:flex-row' : 'lg:flex-row-reverse'} md:items-center justify-evenly mt-16 mb-28`}>
            <div className={`flex-1 ${isEven ? 'md:mr-8' : 'md:ml-8'} text-center`}>
                <GatsbyImage alt={title} image={img} className="w-full" objectFit="contain"/>
            </div>
            <div className={`flex flex-col flex-1 ${isEven ? 'md:ml-8' : 'md:mr-8'}\`} text-center md:text-left`}>
                <span className="text-3xl md:text-4xl font-bold mt-4 mb-1">{title}</span>
                <span className="text-2xl md:text-3xl mb-3">{subtitle}</span>
                <div className="my-2 text-lg flex flex-col gap-2 text-left" dangerouslySetInnerHTML={{__html: content}}/>
                {
                    (appStoreLink || playStoreLink || link) &&
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                        {appStoreLink &&
                            <CTAButton text={intl.formatMessage({id: "appStore"})}
                                       onClick={() => window.open(appStoreLink, '_blank')}/>}
                        {playStoreLink &&
                            <CTAButton text={intl.formatMessage({id: "playStore"})}
                                       onClick={() => window.open(playStoreLink, '_blank')}/>}
                        {link && <CTAButton text={intl.formatMessage({id: "website"})}
                                            onClick={() => window.open(link, '_blank')}/>}
                    </div>
                }
            </div>
        </div>
    )
}

export default ProjectTile
